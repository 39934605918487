<template lang="pug">
  #car-class-rows
    #car-class-definitions-wrapper
      CarClassDefinition(
        v-for="carClass in carClassesForPage"
        :key="carClass.id"
        :car-class="carClass"
        :ota-data="otaData(carClass.id)"
      )
    ScrollSync.car-class-rows-wrapper(horizontal)
      CarClassRow(
        v-for="carClass in carClassesForPage"
        :key="carClass.id"
        :ota-data="otaData(carClass.id)"
        :inventory-days-data="inventoryDaysData(carClass.id)"
        :car-class="carClass"
        :sale-stop-days="saleStopDays"
        :grouped-date-range="groupedDateRange"
        :shared-inventory-enabled="sharedInventoryEnabled"
        :changed-inventories="changedInventories"
        :invalid-inventories="invalidInventories"
        :current-date="currentDate"
        @change-inventory="$emit('change-inventory', $event)"
      )
</template>

<script>
  // components
  import CarClassDefinition from "./CarClassDefinition"
  import CarClassRow from "./CarClassRow"

  // misc
  import { isEmpty } from "lodash-es"

  export default {
    props: {
      groupedDateRange: Object,
      // {
      //   [carClassId]: {
      //     "2020/01/01": {
      //       shop_inventory_limit: "",
      //       shared_inventory_limit: "",
      //       blocked_inventory: "",
      //       available_inventory: "",
      //       booked_inventories_by_ota: {,
      //         1: 10,
      //         2: 5
      //       },
      //       sale_stop_by_ota: {
      //         1: { ota_id: 1, sale_stop: true, disabled: false },
      //         2: { ota_id: 2, sale_stop: false, disabled: false }
      //       }
      //     }
      //   }
      // }
      inventories: {
        type: Object,
        default: () => {}
      },
      carClassesForPage: Array,
      // [Date, Date]
      saleStopDays: Array,
      // ["2020/01/01", "2020/01/02"]
      sharedInventoryEnabled: Boolean,
      changedInventories: {
        type: Object,
        default: () => new Object()
      },
      invalidInventories: {
        type: Object,
        default: () => new Object()
      },
      currentDate: {
        type: Date,
        default: () => new Date()
      }
    },

    components: {
      CarClassDefinition,
      CarClassRow
    },

    methods: {
      isEmpty,

      otaData(carClassId) {
        return this.inventories[carClassId]?.ota_data || {}
      },

      inventoryDaysData(carClassId) {
        return this.inventories[carClassId]?.inventory_by_days || {}
      }
    }
  }
</script>

<style lang="sass" scoped>
  #car-class-rows
    display: flex

    .car-class-rows-wrapper
      overflow-x: auto
</style>
