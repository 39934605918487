<template lang="pug">
  .sale-stop(:class="{ opened }")
    .data-cell
      AppCheckbox(
        :class="commonSaleStopClasses"
        :indeterminate="indeterminateCommonSaleStop"
        :value="isCommonSaleStop"
        :disabled="isCommonSaleStopDisabled"
        :indeterminate-icon="'hypen'"
        @change="changeCommonSaleStop"
        :highlight-on-hover="false"
      )
    .ota-stop-sale
      .data-cell(
        v-for="{ id } in otasWithoutNicolas"
        :class="{ disabled: isDisabled(id), 'ota-inactive': isOtaInactive(id) }"
      )
        AppCheckbox(
          :class="{ highlight: isChanged(id), 'purple-filled': isOtaFilled(id) }"
          :key="id"
          :value="isOtaSaleStopped(id)"
          :disabled="isDisabled(id)"
          :highlight-on-hover="false"
          @change="changeSaleStop($event, id)"
        )
</template>

<script>
  // components
  import AppCheckbox from "@/components/elements/AppCheckbox"

  // misc
  import { every, filter, map, isEqual, some } from "lodash-es"
  import { mapGetters } from "vuex"

  export default {
    props: {
      otaData: {
        type: Object,
        default: () => new Object()
      },
      saleStopOtaIds: {
        type: Array,
        default: () => new Array()
      },
      initialSaleStopOtaIds: {
        type: Array,
        default: () => new Array()
      },
      opened: {
        type: Boolean,
        default: true
      },
      outdated: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppCheckbox
    },

    computed: {
      ...mapGetters(["otasWithoutNicolas"]),

      isCommonChanged({ initialSaleStopEnabledOtaIds, saleStopEnabledOtaIds }) {
        return !isEqual(initialSaleStopEnabledOtaIds, saleStopEnabledOtaIds)
      },

      isCommonSaleStop({ isCommonSaleStopDisabled, saleStopEnabledOtaIds, enabledOtasCount }) {
        return !isCommonSaleStopDisabled && enabledOtasCount === saleStopEnabledOtaIds.length
      },

      isCommonSaleStopDisabled({ otaData }) {
        return every(otaData, (_, id) => this.isDisabled(id))
      },

      enabledOtaIds({ otaData }) {
        return filter(map(otaData, "ota_id"), id => !this.isDisabled(id))
      },

      saleStopEnabledOtaIds({ saleStopOtaIds }) {
        return filter(saleStopOtaIds, id => !this.isDisabled(id))
      },

      initialSaleStopEnabledOtaIds({ initialSaleStopOtaIds }) {
        return filter(initialSaleStopOtaIds, id => !this.isDisabled(id))
      },

      saleStopDisabledOtaIds({ saleStopOtaIds }) {
        return filter(saleStopOtaIds, id => this.isDisabled(id))
      },

      enabledOtasCount({ enabledOtaIds }) {
        return enabledOtaIds.length
      },

      isEverySaleStopped({ isApplied, activeOtas, isCommonChanged }) {
        return isApplied && every(activeOtas, ota => this.isInitialOtaSaleStopped(ota.id)) && !isCommonChanged
      },

      isSomeSaleStopped({ isApplied, activeOtas, isCommonChanged }) {
        return isApplied && some(activeOtas, ota => this.isInitialOtaSaleStopped(ota.id)) && !isCommonChanged
      },

      commonSaleStopClasses({ isCommonChanged, isEverySaleStopped, isSomeSaleStopped }) {
        return {
          highlight: isCommonChanged,
          "purple-filled": isEverySaleStopped,
          "grey-filled": isSomeSaleStopped && !isEverySaleStopped
        }
      },

      indeterminateCommonSaleStop({ activeOtas }) {
        return some(activeOtas, ota => this.saleStopByOta(ota.id))
      },

      isApplied() {
        return this.initialSaleStopEnabledOtaIds.length > 0
      },

      activeOtas({ otasWithoutNicolas }) {
        return filter(otasWithoutNicolas, ota => {
          return !this.isDisabled(ota.id)
        })
      }
    },

    methods: {
      isDisabled(id) {
        return (
          this.isOtaInactive(id) ||
          this.otaData[id]?.not_matched_car_class ||
          this.otaData[id]?.not_matched_shop ||
          this.otaData[id]?.inactive_source_shop ||
          this.otaData[id]?.inactive_source_car_class ||
          this.outdated
        )
      },

      isOtaInactive(id) {
        return this.otaData[id]?.inactive_ota
      },

      isOtaSaleStopped(id) {
        return this.saleStopOtaIds.includes(id)
      },

      isInitialOtaSaleStopped(id) {
        return this.initialSaleStopOtaIds.includes(id)
      },

      isOtaFilled(id) {
        return this.isApplied && this.isOtaSaleStopped(id)
      },

      isChanged(id) {
        const initialSaleStop = this.isInitialOtaSaleStopped(id)
        const currentSaleStop = this.isOtaSaleStopped(id)

        return initialSaleStop !== currentSaleStop
      },

      changeSaleStop(saleStop, otaId) {
        let newData

        if (saleStop) {
          newData = [...this.saleStopOtaIds, otaId]
        } else {
          newData = filter(this.saleStopOtaIds, otaIdItem => otaIdItem !== otaId)
        }

        this.sendChangedSaleStop(newData)
      },

      saleStopByOta(otaId) {
        return this.isOtaSaleStopped(otaId)
      },

      changeCommonSaleStop(saleStop) {
        const newData = saleStop ? this.enabledOtaIds : []

        this.sendChangedSaleStop([...this.saleStopDisabledOtaIds, ...newData])
      },

      sendChangedSaleStop(saleStopIds) {
        this.$emit("change-sale-stop", saleStopIds)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/inventory-management.sass"

  .sale-stop
    .data-cell
      +data-cell

      border-bottom: none

      ::v-deep
        .app-checkbox
          padding: 0

          &.highlight
            .app-checkbox-icon
              background: #f3f4f6
              border-color: $default-gray-medium


              svg, .svg-fillable
                fill: $default-gray !important

          .app-checkbox-icon
            margin: 0 !important
            border: 1px solid $default-purple

        .grey-filled
          .app-checkbox-icon
            background-color: $default-gray
            border-color: $default-gray-medium
          .svg-fillable
            fill: $default-white

        .purple-filled
          .app-checkbox-icon
            background-color: $default-purple
          .svg-fillable
            fill: $default-white

    .ota-stop-sale
      display: none

    &:not(.opened)
      .data-cell
        padding-bottom: 1px

    &.opened
      .data-cell
        border-bottom: 1px solid $border-element-color

      .ota-stop-sale
        display: block

        .data-cell
          &:last-child
            border-bottom: none

          &.ota-inactive
            background: $default-gray-light

          &.ota-inactive

            ::v-deep
              .app-checkbox-icon
                border-color: $default-red
</style>
