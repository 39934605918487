<template lang="pug">
  .car-class-inventory
    .data-cells(
      :class="{ disabled }"
      :data-date="date"
    )
      .data-cell
        template(v-if="isNicolasEnabled")
          p.inventory(v-if="inventoryData.shop_inventory_limit !== null") {{ inventoryData.shop_inventory_limit }}
          p.inventory-placeholder(v-else) —
        AppNumberInput(
          v-else
          data-inventory-field="shop"
          :value="inventoryData.shop_inventory_limit"
          :disabled="disabled",
          :invalid="invalid || $v.inventoryData.shop_inventory_limit.$invalid"
          :failed="isFailedExport"
          :changed="isChangedTotalInventory"
          @input="changeInventoryValue('shop_inventory_limit', $event)"
        )
      BookedInventories(
        :total="bookedInventoryTotal"
        :booked-inventories-by-ota="inventoryData.booked_inventories_by_ota"
        :disabled="disabled"
        :toggler="togglers[getTogglerId('bookedInventoryToggler')]"
        :ota-data="otaData"
      )
      .data-cell(v-if="!isOrixOrganization && !isNicolasEnabled")
        AppNumberInput(
          data-inventory-field="blocked"
          :value="inventoryData.blocked_inventory"
          :max="availableInventoryForBlocking"
          :disabled="disabled",
          :invalid="invalid || $v.inventoryData.blocked_inventory.$invalid"
          :changed="isChangedBlockedInventory"
          @input="changeInventoryValue('blocked_inventory', $event)"
        )
      AvailableInventories(
        :is-value-set="!!inventoryData.id"
        :total="availableInventory"
        :shop-available="shopAvailable"
        :shared-available="sharedAvailable"
        :toggler="togglers[getTogglerId('availableInventoryToggler')]"
      )
      SaleStop(
        :outdated="outdated"
        :ota-data="otaData"
        :sale-stop-ota-ids="inventoryData.sale_stop_ota_ids"
        :initial-sale-stop-ota-ids="inventoryDayItem.sale_stop_ota_ids"
        :opened="togglers[getTogglerId('saleStopToggler')]"
        @change-sale-stop="changeInventoryValue('sale_stop_ota_ids', $event)"
      )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withValidations from "@/mixins/withValidations"
  import {
    bookedInventoryTotal,
    shopAvailable,
    sharedAvailable,
    isChanged,
    availableInventory,
    cleanedInventoryData
  } from "@/pages/InventoryManagement/inventoryValidation"

  // components
  import BookedInventories from "./BookedInventories"
  import AvailableInventories from "./AvailableInventories"
  import SaleStop from "./SaleStop"
  import AppCheckbox from "@/components/elements/AppCheckbox"
  import AppNumberInput from "@/components/elements/AppNumberInput"

  // misc
  import { isNumber } from "lodash-es"
  import { mapGetters, mapState } from "vuex"

  const validationsMixin = withValidations(({ minValue, integer }) => ({
    inventoryData: {
      shop_inventory_limit: {
        minValue: minValue(0),
        integer
      },
      shared_inventory_limit: {
        minValue: minValue(0),
        integer
      },
      blocked_inventory: {
        minValue: minValue(0),
        integer
      }
    }
  }))

  export default {
    props: {
      carClass: Object,
      inventoryDayItem: {
        type: Object,
        default: () => ({
          available_inventory: undefined,
          blocked_inventory: undefined,
          booked_inventories_by_ota: {},
          id: undefined,
          sale_stop_ota_ids: [],
          shared_inventory_limit: undefined,
          shop_inventory_limit: undefined,
          shop_inventory_available: undefined,
          shared_inventory_available: undefined
        })
      },
      otaData: Object,
      sharedInventoryEnabled: Boolean,
      date: String,
      changedInventory: {
        type: Object,
        default: () => new Object()
      },
      invalidInventories: Object,
      outdated: Boolean
    },

    components: {
      AppCheckbox,
      SaleStop,
      BookedInventories,
      AvailableInventories,
      AppNumberInput
    },

    mixins: [withPermissions, validationsMixin],

    watch: {
      inventoryDayItem: {
        deep: true,

        handler() {
          this.setInventoryData()
        }
      },

      changedInventory() {
        this.setInventoryData()
      }
    },

    created() {
      this.setInventoryData()
    },

    data() {
      return {
        inventoryData: {}
      }
    },

    computed: {
      ...mapGetters(["isOrixOrganization", "isNicolasEnabled"]),
      ...mapState("inventoryManagement", ["togglers"]),

      invalid({ invalidInventories, carClass, date }) {
        return invalidInventories[`${carClass.id},${date}`]
      },

      disabled() {
        return !this.hasEditPermission() || this.outdated
      },

      bookedInventoryTotal({ inventoryData }) {
        return bookedInventoryTotal(inventoryData)
      },

      shopAvailable({ isChanged, inventoryData }) {
        return shopAvailable(isChanged, inventoryData)
      },

      sharedAvailable({ isChanged, inventoryData }) {
        return sharedAvailable(isChanged, inventoryData)
      },

      availableInventory({ isChanged, inventoryData, sharedAvailable, shopAvailable }) {
        return availableInventory(isChanged, inventoryData, sharedAvailable, shopAvailable)
      },

      availableInventoryForBlocking({ inventoryData }) {
        const { blocked_inventory, shop_inventory_limit } = inventoryData

        return Math.max(Math.min(blocked_inventory + this.shopAvailable, shop_inventory_limit), 0)
      },

      isChanged({ inventoryDayItem, inventoryData }) {
        return isChanged(inventoryDayItem, inventoryData)
      },

      isChangedTotalInventory({ inventoryDayItem, inventoryData: { shop_inventory_limit } }) {
        return inventoryDayItem.shop_inventory_limit != shop_inventory_limit
      },

      isChangedSharedInventory({ inventoryDayItem, inventoryData: { shared_inventory_limit } }) {
        return inventoryDayItem.shared_inventory_limit != shared_inventory_limit
      },

      isChangedBlockedInventory({ inventoryDayItem, inventoryData: { blocked_inventory } }) {
        return inventoryDayItem.blocked_inventory != blocked_inventory
      },

      isFailedExport({ inventoryData }) {
        return inventoryData.export_is_notified
      }
    },

    methods: {
      isNumber,

      setInventoryData() {
        let { shared_inventory_limit, shop_inventory_limit, sale_stop_ota_ids, blocked_inventory } =
          this.changedInventory.inventory || {}

        blocked_inventory ??= this.inventoryDayItem.blocked_inventory
        shared_inventory_limit ??= this.inventoryDayItem.shared_inventory_limit
        shop_inventory_limit ??= this.inventoryDayItem.shop_inventory_limit
        sale_stop_ota_ids ??= this.inventoryDayItem.sale_stop_ota_ids

        this.inventoryData = {
          ...this.inventoryDayItem,
          shared_inventory_limit,
          shop_inventory_limit,
          sale_stop_ota_ids,
          blocked_inventory
        }
      },

      changeInventoryValue(inventoryPart, value) {
        this.inventoryData[inventoryPart] = value
        this.inventoryData.available_inventory = Math.max(this.availableInventory, 0)
        this.sendChangedInventory()
      },

      sendChangedInventory() {
        this.$emit("change-inventory", {
          id: this.inventoryData.id,
          date: this.date,
          inventory: cleanedInventoryData(this.inventoryData)
        })
      },

      getTogglerId(name) {
        return `${name}${this.carClass.id}`
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/inventory-management.sass"

  .car-class-inventory
    border-top: 1px solid $border-element-color

    &:last-child
      .actions
        width: 36px

      ::v-deep
        .data-cells
          .data-cell
            width: 36px
            border-right: none
            margin-left: -1px

        .sale-stop, .booked-inventories
          .data-cell
            border-right: none
            margin-right: -1px

            &.disabled
              border-left: 1px solid $border-element-color
    &.opened
      .data-cell
        border-bottom: 1px solid $border-element-color

    .data-cells
      .data-cell
        +data-cell
</style>
