<template lang="pug">
  .available-inventories(:class="{ opened: toggler }")
    .data-cell
      p.inventory.bold(:class="{ invalid: isTotalInvalid }") {{ total }}
    .data-cells-availables
      .data-cell
        p.inventory {{ shopAvailable }}
</template>

<script>
  export default {
    props: {
      total: Number,
      shopAvailable: Number,
      sharedAvailable: Number,
      toggler: Boolean,
      isValueSet: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isTotalInvalid() {
        return this.isValueSet && this.total < 1
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/inventory-management.sass"

  .available-inventories
    .data-cells-availables
      display: none

    .data-cell
      +data-cell

      .invalid
        color: $default-red

      .bold
        font-weight: 600

      &.disabled
        background: $default-gray-light

      ::v-deep
        .app-checkbox
          padding: 0

          .app-checkbox-icon
            margin: 0 !important
            border: 1px solid $default-purple

    &.opened
      .data-cell
        border-bottom: 1px solid $border-element-color

      .data-cells-availables
        display: block
</style>
