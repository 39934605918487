<template lang="pug">
  .booked-inventories(:class="{ opened: toggler }")
    .data-cell
      p.inventory(v-if="total") {{ total }}
      p.inventory-placeholder(v-else) —
    .data-cells-by-ota
      .data-cell(
        v-for="{ id } in otasWithoutNicolas"
        :class="{ 'ota-inactive': isOtaInactive(id) }"
      )
        p.inventory(v-if="bookedInventoriesByOta[id]") {{ bookedInventoriesByOta[id] }}
        p.inventory-placeholder(v-else) —
</template>

<script>
  // misc
  import { mapGetters } from "vuex"

  export default {
    props: {
      total: Number,
      bookedInventoriesByOta: Object,
      disabled: Boolean,
      toggler: Boolean,
      otaData: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: mapGetters(["otasWithoutNicolas"]),

    methods: {
      isOtaInactive(id) {
        return this.otaData[id]?.inactive_ota
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/inventory-management.sass"

  .booked-inventories
    .data-cells-by-ota
      display: none

    .data-cell
      +data-cell

      &.ota-inactive
        background: $default-gray-light
        color: $default-gray

      ::v-deep
        .app-checkbox
          padding: 0

          .app-checkbox-icon
            margin: 0 !important
            border: 1px solid $default-purple

    &.opened
      .data-cell
        border-bottom: 1px solid $border-element-color

      .data-cells-by-ota
        display: block
</style>
