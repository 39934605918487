<template lang="pug">
  .car-class-row(:data-car-class-id="carClass.id")
    .car-class-inventories
      .month(v-for="month in mappedGroupedDates")
        .inventories
          CarClassInventory(
            v-for="day in month"
            :outdated="day.date < currentDate"
            :key="day.formatted"
            :car-class="carClass"
            :inventory-day-item="inventoryDaysData[day.formatted]"
            :date="day.formatted"
            :shared-inventory-enabled="sharedInventoryEnabled"
            :ota-data="otaData"
            :changed-inventory="changedInventories[[carClass.id, day.formatted]]"
            :invalid-inventories="invalidInventories"
            @change-inventory="changeInventory"
          )
</template>

<script>
  // components
  import CarClassDefinition from "./CarClassDefinition"
  import CarClassInventory from "./CarClassInventory"

  // misc
  import { format as dateFormat } from "date-fns"
  import { mapValues } from "lodash-es"

  export default {
    components: {
      CarClassDefinition,
      CarClassInventory
    },

    props: {
      carClass: Object,
      inventoryDaysData: {
        type: Object,
        default: () => new Object()
      },
      groupedDateRange: Object,
      otaData: Object,
      sharedInventoryEnabled: Boolean,
      changedInventories: {
        type: Object,
        default: () => new Object()
      },
      invalidInventories: Object,
      currentDate: Date
    },

    computed: {
      mappedGroupedDates() {
        return mapValues(this.groupedDateRange, monthDates => {
          return monthDates.map(date => {
            return {
              date,
              formatted: dateFormat(date, "yyyy/MM/dd")
            }
          })
        })
      }
    },

    methods: {
      changeInventory(inventory) {
        const inventoryObject = { ...inventory, car_class_id: this.carClass.id }
        this.$emit("change-inventory", inventoryObject)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .car-class-row
    display: flex
    border-bottom: 3px solid $default-purple-light-line
    width: fit-content

    .car-class-inventories
      display: flex

      .month
        border-right: 1px solid $default-purple-light-line
        padding-top: 50px

        &:last-child
          border-right: 1px solid $default-purple-light-line

        .inventories
          display: flex
</style>
