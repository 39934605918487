<template lang="pug">
  .car-class-definition(:data-car-class-id="carClass.id")
    .def.name(:title="carClass.name") {{ carClass.name }}
    .def.inventory
      span {{ $t("activerecord.attributes.inventory.shop_inventory") }}
      AppTooltip.info-icon(
        :title="$t('inventory_management.fields_description.shop_inventory')"
      )
    .def.inventory.expandable(
      @click="toggle('bookedInventoryToggler')"
    )
      div {{ $t("activerecord.attributes.inventory.booked_inventory") }}
      FaIcon.chevron(
        :icon="bookedInventoryToggler ? 'chevron-up' : 'chevron-down'"
      )
    .ota-list(
      :class="{ opened: bookedInventoryToggler }"
    )
      OtaDefinition(
        v-for="{ id, name } in otasWithoutNicolas"
        :key="id"
        :ota-data="otaData[id]"
        :ota-name="name"
      )

    .def.inventory(v-if="!isOrixOrganization && !isNicolasEnabled")
      span {{ $t("activerecord.attributes.inventory.blocked_inventory") }}
      AppTooltip.info-icon(
        :title="$t('inventory_management.fields_description.blocked_inventory')"
      )

    .def.inventory
      span {{ $t("activerecord.attributes.inventory.shop_available") }}
      AppTooltip.info-icon(
        :title="$t('inventory_management.fields_description.available_inventory')"
      )
    .def.inventory.expandable.no-border(
      :class="{ opened: saleStopToggler }"
      @click="toggle('saleStopToggler')"
    )
      div
        span {{ $t("activerecord.attributes.inventory.sale_stop") }}
        AppTooltip.info-icon(
          :title="$t('inventory_management.fields_description.sale_stop')"
        )

      FaIcon.chevron(
        :icon="saleStopToggler ? 'chevron-up' : 'chevron-down'"
      )
    .ota-list.no-border(
      :class="{ opened: saleStopToggler }"
    )
      OtaDefinition(
        v-for="{ id, name } in otasWithoutNicolas"
        :key="id"
        :ota-data="otaData[id]"
        :ota-name="name"
      )
</template>

<script>
  // components
  import AppTooltip from "@/components/elements/AppTooltip"

  // misc
  import { forEach } from "lodash-es"
  import { mapGetters, mapMutations, mapState } from "vuex"
  import { TOGGLER_NAMES } from "./constants"

  export default {
    components: {
      AppIcon: () => import("@/components/elements/AppIcon"),
      OtaDefinition: () => import("./OtaDefinition"),
      AppTooltip
    },

    props: {
      carClass: Object,
      otaData: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      ...mapGetters(["otasWithoutNicolas", "isOrixOrganization", "isNicolasEnabled"]),

      ...mapState("inventoryManagement", ["togglers"]),

      bookedInventoryToggler() {
        return this.togglers[this.getTogglerId("bookedInventoryToggler")]
      },

      availableInventoryToggler() {
        return this.togglers[this.getTogglerId("availableInventoryToggler")]
      },

      saleStopToggler() {
        return this.togglers[this.getTogglerId("saleStopToggler")]
      }
    },

    created() {
      forEach(TOGGLER_NAMES, toggleName => {
        const uniqId = this.getTogglerId(toggleName)

        this.setToggler({
          uniqId,
          toggleState: !!this.togglers[uniqId]
        })
      })
    },

    methods: {
      ...mapMutations("inventoryManagement", {
        setToggler: "SET_TOGGLERS"
      }),

      toggle(name) {
        const uniqId = this.getTogglerId(name)
        this.setToggler({
          uniqId,
          toggleState: !this.togglers[uniqId]
        })
      },

      getTogglerId(name) {
        return `${name}${this.carClass.id}`
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .car-class-definition
    background-color: $default-white
    padding-top: 21px
    border-right: 1px solid $default-purple-light-line
    border-bottom: 3px solid $default-purple-light-line
    width: 240px

    .def
      background-color: $default-white
      border-bottom: 1px solid $border-element-color
      font-weight: 800
      height: 30px
      line-height: 30px
      margin: 0

      &.name
        border-bottom: 1px solid $border-element-color
        color: $default-purple
        font-size: 1.2rem
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

      &.inventory
        color: $link-color
        font-size: 0.8rem
        text-transform: uppercase
        cursor: default

        .toggle-icon
          width: 16px
          height: 16px
          float: right
          vertical-align: middle

        &.expandable
          display: flex
          justify-content: space-between
          align-items: center

          &.no-border
            border-bottom: none

          &.opened
            border-bottom: 1px solid $border-element-color

      .info-icon
        margin-left: 5px
        opacity: 0.7

        &:hover
          opacity: 1

    .chevron
      color: $icon-color
      margin-right: 10px
      font-weight: 200

    .available-row-list
      background-color: $default-white
      border: 0
      max-height: 0
      overflow: hidden
      transition: padding-left 0.2s ease-out

      &.opened
        max-height: 500px
        padding-left: 25px

      .available-row
        height: 30px
        border: 0
        color: $link-color
        font-size: 0.8rem
        font-weight: 400
        padding-left: 5px
        text-transform: uppercase
        border-bottom: 1px solid $border-element-color

        p
          height: 29px // 29px = 30px wrapper height - 1px wrapper border
          line-height: 29px
          margin: 0
          vertical-align: middle
          width: 100%

    .ota-list
      background-color: $default-white
      border: 0
      max-height: 0
      overflow: hidden
      transition: padding-left 0.2s ease-out

      &.opened
        max-height: 500px
        padding-left: 25px

      &.no-border
        .ota
          &:last-child
            p
              border-bottom: none
</style>
